export enum SupportArticles {
  HOW_TO_CONTACT_SUPPORT = "HOW_TO_CONTACT_SUPPORT",
  SIGN_IC_AGREEMENT = "SIGN_IC_AGREEMENT",
  REFERRAL_BONUS = "REFERRAL_BONUS",
  WHY_WORKERS_SEE_QUIZ = "WHY_WORKERS_SEE_QUIZ",
}

export enum SupportContext {
  ONBOARDING_ERROR = "Onboarding Error",
  ONBOARDING_CHECKR_ERROR = "Onboarding Checkr Error",
  NO_USER_SCREEN = "No User Screen",
  LOGIN_OTP_DELIVERY = "Login OTP Delivery",
  SIGN_UP_OTP_DELIVERY = "Signup OTP Delivery",
  AGENTPROFILE_FETCH_ERROR = "Agentprofile Fetch Error",
  ERROR_FALLBACK_SCREEN = "Error Fallback Screen",
  PDF_LOAD_FAILURE = "Pdf Load Failure",
  UNSUPPORTED_PDF_PLATFORM = "Unsupported Pdf Platform",
  NO_WORKER_AGREEMENT_FILE_AVAILABLE = "No Worker Agreement File Available",
  NO_AGREEMENT_TEMPLATE_FILE_AVAILABLE = "No Agreement Template File Available",
  IC_AGREEMENT_ERROR_ALERT = "IC Agreement Error Alert",
  PHONE_LOGIN_WITH_FACILITY_MOBILE_APP_INSTEAD = "Phone Login With Facility Mobile App Instead",
  EMAIL_LOGIN_WITH_FACILITY_MOBILE_APP_INSTEAD = "Email Login With Facility Mobile App Instead",
  NO_REFERRAL_PROGRAM = "Not eligible for any referral program",
  WORKER_REFERRALS = "Worker Referrals",
  SHARED_SHIFT_NOT_FOUND = "Shift Not Found",
}

/**
 * Note, some of these links are overridden by the FFs where they are used,
 * make sure to update the apropiate FF (if case) when changing the link.
 */
export const SupportArticleLinks = {
  WHY_WORKERS_SEE_QUIZ:
    "https://support.clipboardhealth.com/hc/en-us/articles/22271231321367-Understanding-Facility-Rules-Pre-booking-Quizzes",
  HOW_TO_CONTACT_SUPPORT:
    "https://support.clipboardhealth.com/hc/en-us/articles/4685844772887-How-do-I-contact-support",
  WHY_SSN: "https://support.clipboardhealth.com/hc/en-us/articles/4550144249367",
  HELP_CENTER_WORKER: "https://workers.clipboardhealth.com/",
  BACKGROUND_CHECKS: "https://support.clipboardhealth.com/hc/en-us/articles/4535441657239",
  // DEAD LINK, both here and in the FF
  TIMESHEET_SIGNATURES: "https://support.clipboardhealth.com/hc/en-us/articles/4663665483031",
  ATTENDANCE_SCORE: "https://support.clipboardhealth.com/hc/en-us/articles/8230048132119",
  CLIPBOARD_SCORE:
    "https://cbh-public-assets.s3.us-east-1.amazonaws.com/priority_plus_and_clipboard_score.pdf",
  DNR_REQUESTS_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/7904947056407",
  MULTI_STATE_LICENSES: "https://support.clipboardhealth.com/hc/en-us/articles/9978473726231",
  WORK_WITH_FRIENDS: "https://support.clipboardhealth.com/hc/en-us/articles/10411040507031",
  RATE_NEGOTIATION_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/11915644531351",
  // DEAD LINK, both here and in the FF
  TIME_NEGOTIATION_WORKER: "https://support.clipboardhealth.com/hc/en-us/articles/14972809329175",
  REFERRAL_BONUS: "https://support.clipboardhealth.com/hc/en-us/articles/6514925635991",
  ACCOUNT_STATUS: "https://support.clipboardhealth.com/hc/en-us/articles/12937542998295",
  INSTANT_PAY_INFO:
    "https://support.clipboardhealth.com/hc/en-us/articles/4643349117335-How-to-change-your-payment-options-on-Stripe",
  EXTRA_WORK_BREAKS:
    "https://support.clipboardhealth.com/hc/en-us/articles/21048854607895-What-s-new-about-taking-breaks",
};
